import React from "react"
import Page from "../components/Page"
import { Container, Row, Col } from "react-bootstrap"
import SignUpForm from "../components/forms/SignUpForm"

import RallistaLogo from "../images/rallista-dk.png"
import CarLarge from "../images/car-large.png"
import { useAuthV2 } from "../hooks/useAuth"
import { Link, navigate } from "gatsby"

const styles = {
  container: {
    border: "solid 2px #f8f9fa",
    marginBottom: "4em",
  },
  column: {
    padding: "6em",
  },
}

const SignUpPage = () => {
  const { signUp, user, authInitialized } = useAuthV2()

  React.useEffect(() => {
    if (user && authInitialized) {
      navigate("/dashboard/")
    }
  }, [user, authInitialized])

  const onSubmit = async (username, email, password) => {
    await signUp(email, password, username)
    navigate("/dashboard/")
  }

  if (user || !authInitialized) {
    return null
  }

  return (
    <Page
      seoPath="sign-up"
      seoTitle={"Sign Up"}
      seoKeywords={[
        "rallista",
        "drive",
        "sign up",
        "roads",
        "rally",
        "car rally",
      ].join(" ")}
      seoDescription={"Sign up for a Rallista Account"}
    >
      <Container className="d-flex h-100 p-0 rounded" style={styles.container}>
        <Row className="flex-grow-1 h-100 w-100, m-0">
          <Col
            className="flex-grow-1 align-items-center d-flex p-0 flex-column"
            style={{
              ...styles.column,
              justifyContent: "space-evenly",
              backgroundColor: "#f8f9fa",
            }}
            lg={4}
          >
            <Row>
              <img
                style={{ width: "13em" }}
                src={RallistaLogo}
                alt="rallista logo"
              />
            </Row>
            <Row>
              <img
                style={{ width: "10em" }}
                src={CarLarge}
                alt="rallista car"
              />
            </Row>
          </Col>
          <Col style={styles.column} lg={8}>
            <h2>Sign up to Rallista</h2>
            <p style={{ marginBottom: "2rem" }}>
              Already have an account? <Link to="/sign-in">Sign In</Link>
            </p>
            <SignUpForm onSubmit={onSubmit} />
          </Col>
        </Row>
      </Container>
    </Page>
  )
}

SignUpPage.propTypes = {}

export default SignUpPage
